import image1 from "../Gallery Images/Gallery1.jpg"
import image2 from "../Gallery Images/Gallery2.jpg"
import image3 from "../Gallery Images/Gallery3.jpg"
import image4 from "../Gallery Images/Gallery4.jpg"
import image5 from "../Gallery Images/Gallery5.jpg"
import image6 from "../Gallery Images/Gallery6.jpg"
import image7 from "../Gallery Images/Gallery7.jpg"
import image8 from "../Gallery Images/Gallery8.jpg"
import image9 from "../Gallery Images/Gallery9.jpg"
import image10 from "../Gallery Images/Gallery10.jpg"
import image11 from "../Gallery Images/Gallery11.jpg"
import image12 from "../Gallery Images/Gallery12.jpg"
import image13 from "../Gallery Images/Gallery13.jpg"
import image14 from "../Gallery Images/Gallery14.jpg"
import image15 from "../Gallery Images/Gallery15.jpg"
import image16 from "../Gallery Images/Gallery16.jpg"


export const SliderData = [
    {
        image: image1

    },
    {
        image: image2

    },
    {
        image: image3

    },
    {
        image: image4
    },
    {
        image: image5
    },
    {
        image: image6
    },
    {
        image: image7

    },
    {
        image: image8

    },
    {
        image: image9

    },
    {
        image: image10
    },
    {
        image: image11
    },
    {
        image: image12
    },
    {
        image: image13

    },
    {
        image: image14

    },
    {
        image: image15

    },
    {
        image: image16
    }
]