import image1 from "../Gallery Images/Gallery4.jpg"
import image2 from "../Gallery Images/Gallery6.jpg"
import image3 from "../Gallery Images/Gallery8.jpg"
import image4 from "../Gallery Images/Gallery10.jpg"
import image5 from "../Gallery Images/Gallery11.jpg"
import image6 from "../Gallery Images/Gallery12.jpg"

export const DesktopImageData = [
    {
        image: image1

    },
    {
        image: image2

    },
    {
        image: image3

    },
    {
        image: image4
    },
    {
        image: image5
    },
    {
        image: image6
    }

]